button {
    cursor: pointer;
    background-color: transparent;
    border: transparent;
}

.actionButton {
    background-color: #2C1DFE;
    height: 48px;
    border-radius: 4px;
}