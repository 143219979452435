.policyTerm {
  background-color: #f1eff5;
  padding: 200px 28px 0 120px;
  width: 405px;

  .innerContent {
    width: 257px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .options {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .element {
        display: flex;
        align-items: center;
        gap: 8px;

        cursor: pointer;
      }
    }
  }
}
