.checkboxComponent{
    display: flex;
}

.checkboxPrimary {
    display: flex;
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .checkmark {
        border: 0.5px solid #2C1DFE;
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
    }
}

.checkboxPrimary:hover input~.checkmark {
    background-color: #EEE;
}

.checkboxPrimary {
    input:checked~.checkmark {
        background-color: #2C1DFE;
    }
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkboxPrimary input:checked~.checkmark:after {
    display: block;
}

.checkboxPrimary .checkmark:after {
    left: 4px;
    top: 1px;
    width: 2px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}