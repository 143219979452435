.coursesComponent {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;

    .innerContent {
        width: 100%;
        height: 100%;
    }
}