.label {
  // width: 93px;
  height: 28px;
  display: inline-flex;

  background: #edf5ff;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;

  .labelAboutUs {
    color: #114c98;
    font-size: 10px;
    font-family: Lexend;
    font-weight: 700;
    line-height: 12px;
    word-wrap: break-word;
    margin: 0px;
    padding: 8px 16px;
  }
}

.labelTitle {
  .labelTitleContent {
    padding: 0px;
    margin: 0px;

    color: rgba(0, 0, 0, 0.8);
    font-size: 4rem;
    font-family: Lexend;
    font-weight: 300;
    font-style: normal;
    line-height: 120%;
    word-wrap: break-word;

    .labelTitleDetail {
      color: #2c1dfe;
      font-size: 4rem;
      font-family: Lexend;
      font-weight: 300;
      font-style: normal;
      line-height: 120%;
      word-wrap: break-word;
    }
  }
}

.labelTitelW300H32 {
  .labelTitleContent {
    padding: 0px;
    margin: 0px;

    color: rgba(0, 0, 0, 0.8);
    font-size: 32px;
    font-family: Lexend;
    font-weight: 300;
    line-height: 38.4px;
    word-wrap: break-word;

    .labelTitleDetail {
      color: #2c1dfe;
    }
  }
}

.labelTitleW400H40 {
  .labelTitleContent {
    padding: 0px;
    margin: 0px;

    color: rgba(0, 0, 0, 0.8);
    font-size: 40px;
    font-family: Lexend;
    font-weight: 300;
    word-wrap: break-word;
    line-height: 48px;

    .labelTitleDetail {
      color: #2c1dfe;
    }
  }
}

.labelW400H24 {
  color: rgba(0, 0, 0, 0.9);
  font-size: 24px;
  font-family: Lexend;
  font-weight: 400;
  line-height: 28.8px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW400H32 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 32px;
  font-family: Lexend;
  font-weight: 400;
  line-height: 38.4px;
  word-wrap: break-word;

  &.center {
    text-align: center;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW400H20 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-family: Lexend;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW400H16 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-family: Lexend;
  font-weight: 400;
  line-height: 19.2px;
  word-wrap: break-word;

  &.white {
    color: #f8f8f8;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelContent {
  .labelContentText {
    margin: 0px;
    padding: 0px;
    padding-bottom: 20px;
    font-family: Lexend;
  }
}

.labelW300S40 {
  display: flex;
  justify-content: center;

  color: #fdfdfd;
  font-size: 40px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 48px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW300H20 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;

  &.center {
    text-align: center;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW56H53 {
  color: #2c1dfe;
  font-size: 64px;
  font-family: Font Awesome 6 Pro;
  font-weight: 900;
  line-height: 76.8px;
  word-wrap: break-word;
}

.labelW300H32 {
  font-size: 32px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 38.4px;
  word-wrap: break-word;

  &.white {
    color: #fdfdfd;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW300H40 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 40px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 48px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW500H10 {
  display: flex;
  gap: 8px;

  color: rgba(0, 0, 0, 0.8);
  font-size: 10px;
  font-family: Lexend;
  font-weight: 500;
  line-height: 14.4px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }

  &.blue {
    color: #2c1dfe;
  }

  &.red {
    color: #fe2c1d;
  }
}

.labelW500H12 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-family: Lexend;
  font-weight: 500;
  line-height: 14.4px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }

  &.blue {
    color: #2c1dfe;
  }
}

.labelW500H20 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 20px;
  font-family: Lexend;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW500H24 {
  color: #2c1dfe;
  font-size: 24px;
  font-family: Lexend;
  font-weight: 500;
  line-height: 28.8px;
  word-wrap: break-word;

  &.black {
    color: #000;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW300H12 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 14.4px;
  word-wrap: break-word;

  &.white {
    color: #fdfdfd;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW300H14 {
  color: #090633;
  font-size: 14px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 16.8px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW300H16 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 19.2px;
  word-wrap: break-word;

  &.selected {
    color: #2c1dfe;
    font-weight: 500;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW500H16 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-family: Lexend;
  font-weight: 500;
  line-height: 19.2px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW300h10 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 10px;
  font-family: Lexend;
  font-weight: 300;
  line-height: 12px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW400H10 {
  display: flex;
  gap: 8px;

  color: #000;
  font-size: 12px;
  font-family: Lexend;
  font-weight: 400;
  line-height: 12px;
  word-wrap: break-word;

  &.blue {
    color: #2c1dfe;
  }

  &.red {
    color: #fe2c1d;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }

  .btn {
    font-size: 12px;
    font-family: Lexend;
    font-weight: 400;
    line-height: 12px;
    word-wrap: break-word;
  }
}

.labelW400H12 {
  color: #fff;
  font-size: 12px;
  font-family: Lexend;
  font-weight: 400;
  line-height: 12px;
  word-wrap: break-word;

  &.black {
    color: #000;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }

  .btn {
    font-size: 12px;
    font-family: Lexend;
    font-weight: 400;
    line-height: 12px;
    word-wrap: break-word;
  }
}

.labelW400H14 {
  color: #000;
  font-size: 14px;
  font-family: Lexend;
  font-weight: 400;
  line-height: 12px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW600H14 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: Lexend;
  font-weight: 600;
  line-height: 16.8px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW600H42 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 42px;
  font-family: Lexend;
  font-weight: 600;
  line-height: 16.8px;
  word-wrap: break-word;

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW700H16 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-family: Lexend;
  font-weight: 700;
  line-height: 16.8px;
  word-wrap: break-word;

  &.blue {
    color: #2c1dfe;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

.labelW700H26 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 26px;
  font-family: Lexend;
  font-weight: 700;
  line-height: 16.8px;
  word-wrap: break-word;

  &.blue {
    color: #2c1dfe;
  }

  .labelContent {
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 768px) {
  .labelW300H32 {
    font-size: 20px;
    font-family: Lexend;
    font-weight: 300;
    line-height: 24px;
    word-wrap: break-word;
  }

  .labelTitelW300H32 {
    .labelTitleContent {
      font-size: 24px;
      font-weight: 300;
      line-height: 28.8px;
    }
  }

  .labelW600H14 {
    font-size: 12px;
    line-height: 14.4px;
  }
}

@media (max-width: 415px) {
  .labelTitle {
    .labelTitleContent {
      color: rgba(0, 0, 0, 0.8);
      font-size: 32px;
      font-family: Lexend;
      font-weight: 300;
      line-height: 38.4px;
      word-wrap: break-word;

      .labelTitleDetail {
        color: #2c1dfe;
        font-size: 32px;
        font-family: Lexend;
        font-weight: 300;
        line-height: 38.4px;
        word-wrap: break-word;
      }
    }
  }

  .labelW300S40 {
    color: #fdfdfd;
    font-size: 32px;
    font-family: Lexend;
    font-weight: 300;
    line-height: 38.4px;
    word-wrap: break-word;

    text-align: center;
  }

  .labelW300H32 {
    font-size: 16px;
    font-family: Lexend;
    font-weight: 300;
    line-height: 19.2px;
    word-wrap: break-word;
  }

  .labelW500H16 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 8.11px;
    font-family: Lexend;
    font-weight: 500;
    line-height: 9.73px;
    word-wrap: break-word;
  }

  .labelW400H24 {
    font-size: 20px;
    line-height: 24px;
  }

  .labelW300H40 {
    font-size: 32px;
    line-height: 38.4px;
  }

  .labelW300H20 {
    font-size: 16px;
    font-weight: 300;
    line-height: 19.2px;
  }

  .labelW300h10 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 5.07px;
    font-family: Lexend;
    font-weight: 300;
    line-height: 6.08px;
    word-wrap: break-word;
  }

  .labelTitleW400H40 {
    .labelTitleContent {
      font-size: 32px;
      font-weight: 300;
      line-height: 38.4px;
    }
  }
}
