.inputPrimary {
    label {
        background-color: #FFF;
    }

    .innerContent {
        margin-right: 8px;
    }

    .innerContentVerify {
        display: flex;
        gap: 8px;

        .btnEye {
            cursor: pointer;
            background-color: transparent;
            border: transparent;
        }
    }
}

.inputCode {
    display: flex;
    gap: 16px;
    justify-content: center;

    .codeInner {
        display: flex;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: 0.5px solid #2C1DFE;
        border-radius: 4px;

        input {
            width: 16px;
            height: 90%;
            border: transparent;
            outline: transparent;
        }
    }
}