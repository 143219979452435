.policyText {
  display: flex;
  flex-direction: column;
  gap: 48px;

  .informations {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.items {
      gap: 16px;
    }

    .title {
      color: rgba(0, 0, 0, 0.8);
      font-size: 20px;
      font-family: Lexend;
      font-weight: 500;
      line-height: 24px;
      word-wrap: break-word;

      padding: 0px;
      margin: 0px;
    }

    .content {
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
      font-family: Lexend;
      font-weight: 300;
      line-height: 19.2px;
      word-wrap: break-word;

      padding: 0px;
      margin: 0px;

      .link {
        color: rgba(0, 0, 0, 0.6);
      }

      &.emphasis {
        font-weight: 500;
      }

      .underline {
        text-decoration: underline;
      }

      .changeTerm {
        cursor: pointer;
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.6);
        font-size: 16px;
        font-family: Lexend;
        font-weight: 300;
        line-height: 19.2px;
        word-wrap: break-word;

        padding: 0px;
        margin: 0px;
      }
    }

    .contentList {
      margin: 0px;
    }
  }
}

@media (max-width: 425px) {
  .policyText {
    .informations {
      .link {
        font-size: 75%;
      }
    }
  }
}
