.privacyPolicy {
  padding-top: 12%;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 10%;

  display: flex;
  flex-direction: column;
  gap: 80px;

  .title {
    display: flex;
    flex-direction: column;
    gap: 48px;

    .titleHeader {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .titleBodyTerm {
      display: flex;
      justify-content: space-between;

      .menu {
        display: flex;
        gap: 16px;

        .menuItem {
          color: rgba(0, 0, 0, 0.8);
          font-size: 16px;
          font-family: Lexend;
          font-weight: 300;
          line-height: 19.2px;
          word-wrap: break-word;

          background-color: transparent;
          border: none;
          cursor: pointer;

          &.selected {
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .privacyPolicy {
    padding-top: 20%;
  }
}

@media (max-width: 768px) {
  .privacyPolicy .title .titleBodyTerm {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .privacyPolicy .title .titleBodyTerm .menu {
    gap: 0.2rem;
  }

  .privacyPolicy .title .titleBodyTerm .menu .menuItem {
    font-size: 0.8rem;
  }
}

@media (max-width: 425px) {
  .privacyPolicy {
    padding-top: 44%;
    padding-left: 3%;
    padding-right: 3%;
  }
}
