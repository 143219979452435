.ServiceTokenLoginContent {
  overflow: hidden;
  display: flex;
  height: 100vh;
  width: 100%;

  .leftConten {
    display: flex;
    flex-direction: column;
    margin: 95px 0 0 98px;
    gap: 226px;
    width: 568px;

    .mainBannerText {
      display: none;
    }
  }

  .rightContent {
    background-color: #9c1dfe;
    width: calc(100% - 568px);
  }
}

@media (max-width: 1170px) {
  .ServiceTokenLoginContent {
    display: flex;
    background-color: #9c1dfe;
    background-image: url(../../assets/icons/background-login.svg);
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: auto;

    .rightContent {
      display: none;
    }

    .leftConten {
      margin: 0;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-top: 18%;
      border-top-right-radius: 1.5rem;
      border-top-left-radius: 1.5rem;

      .logoBlue {
        display: none;
      }

      .mainBannerText {
        display: flex;
        top: calc(10% - 40px);
        height: 2rem;
        left: 42%;
      }
    }
  }
}

@media (max-width: 960px) {
  .ServiceTokenLoginContent {
    .leftConten {
      .mainBannerText {
        left: 40%;
      }
    }
  }
}

@media (max-width: 640px) {
  .ServiceTokenLoginContent {
    .leftConten {
      .mainBannerText {
        left: 35%;
      }
    }
  }
}

@media (max-width: 535px) {
  .ServiceTokenLoginContent {
    .leftConten {
      margin-top: 26%;

      .mainBannerText {
        left: 32%;
      }
    }
  }
}

@media (max-width: 425px) {
  .ServiceTokenLoginContent {
    background-image: none;

    .leftConten {
      margin-top: 34%;

      .mainBannerText {
        left: 28%;
      }

      .LoginFormContent {
        width: 80%;
        .labelW600H42 {
          font-size: 2rem;
        }

        .loginFormEmailPassword {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .ServiceTokenLoginContent {
    .leftConten {
      .mainBannerText {
        left: 26%;
      }
    }
  }
}

@media (max-width: 320px) {
  .ServiceTokenLoginContent {
    .leftConten {
      .mainBannerText {
        left: 20%;
      }
    }
  }
}
