.steps {
  display: flex;
  justify-content: space-between;
  padding: 8% 120px;
  
  .descriptions {
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    .subsclib {
      cursor: pointer;
    }

    .principal {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .bgMax {
        width: 113px;
      }

      .descMax {
        width: 387px;
      }
    }
  }

  .vids {
    display: flex;
    align-items: center;

    iframe {
      width: 30rem;
      height: 80%;
      border: none;
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 960px) {
  .steps {
    flex-direction: column;
    gap: 84px;
    padding: 7%;

    .descriptions {
      .principal {
        .descMax {
          width: 100%;
        }
      }
    }

    .vids {
      justify-content: center;

      iframe {
        width: 39rem;
        height: 21.9375rem;
      }
    }
  }
}

@media (max-width: 535px) {
  .steps {
    margin-top: 4rem;

    .descriptions {
      .principal {
        .descMax {
          .labelContent {
            font-size: 2rem;
          }
        }
      }
    }

    .vids {
      iframe {
        width: 39rem;
        height: 15.9375rem;
      }
    }
  }
}
