.owner {
  background-color: #090633;
  background-image: url('../../assets/icons/background-about-us.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  margin: 0px;
  padding-top: 10rem;

  .ownerCards {
    display: flex;
    flex-direction: column;
    padding: 8%;
  }
}

.ownerCards {
  display: grid;
  gap: 106px;
}

.ownerCard {
  height: 315px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5rem;

  .picture {
    border-radius: 4px;
  }

  .descriptionMobile {
    display: none;
  }

  .areaImage {
    display: none;
  }

  .areaIcon {
    display: flex;
    align-items: flex-start;
    height: 69%;
  }

  .ownerAbourt {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .ownerDescription {
      .h48 {
        height: 48px;
      }

      .h24 {
        height: 24px;
      }
    }
  }
}

@media (max-width: 960px) {
  .owner {
    .labelW300S40 {
      font-size: 2.5rem;
    }

    .labelW300H32 {
      font-size: 1.25rem;
    }

    .labelW500H24 {
      font-size: 1.5rem;
    }

    .labelW300H12 {
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 768px) {
  .owner {
    padding-top: 6rem;

    .picture {
      height: 12rem;
    }

    .labelW300H32 {
      font-size: 1rem;
    }

    .labelW500H24 {
      font-size: 1.25rem;
    }

    .labelW300H12 {
      font-size: 0.65rem;
    }

    .ownerCard {
      gap: 2rem;
    }

    .ownerCards {
      gap: 1rem;
    }

    .ownerCard .areaIcon {
      img {
        height: 2rem;
      }
    }

    .ownerCard .ownerAbourt .ownerDescription .h48 {
      height: 1.5rem;
    }

    .ownerCard .ownerAbourt .ownerDescription .h24 {
      height: 1rem;
    }
  }
}

@media (max-width: 620px) {
  .owner {
    .labelW300S40 {
      font-size: 1.5rem;
    }

    .picture {
      height: 10rem;
    }
  }
}

@media (max-width: 535px) {
  .owner .picture {
    display: none;
  }

  .owner .labelW300S40 {
    margin-left: 3%;
    margin-right: 3%;
  }

  .owner .descriptionMobile {
    display: flex;
  }

  .owner .ownerCards {
    gap: 2rem;
  }

  .owner .ownerCard {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .ownerCard .ownerAbourt {
    display: none;
  }

  .ownerCard .areaIcon {
    height: 9%;
  }

  .ownerCard .areaImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    img {
      display: flex;
      height: 8rem;
    }

    .ownerDescription {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }
  }
}
