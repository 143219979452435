body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdfdfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #090633 #eae9ef;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.375rem;
}

*::-webkit-scrollbar-track {
  background: #eae9ef;
}

*::-webkit-scrollbar-thumb {
  background-color: #090633;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 4px 0px rgba(5, 3, 26, 0.08),
    0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.03);
}

#robbu-whatsapp-button {
  z-index: 100;
}
