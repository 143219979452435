.aboutUsContent {
  display: flex;
  flex-direction: column;
  padding-left: 7%;
  padding-right: 7%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-image: url(../../assets/icons/background-aboutus.svg);
  background-position-x: left;
  background-position-y: 85%;
  background-repeat: no-repeat;
  background-size: auto;

  .aboutUsGrid {
    padding: 0px;
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 8%;

    .contentLeft {
      max-width: 24.1875rem;
    }
  }
}

@media (max-width: 960px) {
  .aboutUsContent {
    padding-left: 5%;
    padding-right: 5%;

    .aboutUsGrid {
      .contentLeft {
        .labelTitleContent {
          font-size: 3rem;
        }
        .labelTitleDetail {
          font-size: 3rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .aboutUsContent {
    .aboutUsGrid {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      margin-top: 3rem;
    }

    .contentLeft {
      .labelTitleContent {
        font-size: 2rem;
      }
      .labelTitleDetail {
        font-size: 2rem;
      }
    }
  }
}
