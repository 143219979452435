.partner {
  padding: 5%;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 72px;

  .logos {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .h40 {
      height: 40px;
    }

    .h100 {
      height: 100px;
    }
  }
}
