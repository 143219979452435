.talent{
  overflow: hidden;
  .mainText {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .store {
    display: flex;
    gap: 1rem;

    img {
      cursor: pointer;
    }
  }

  .mainText {
    margin: 120px 0px;
    padding: 0px;

    display: flex;
    align-items: center;

    .contentMainText {
      width: 590px;
      text-align: center;
    }
  }
}

@media (max-width: 620px) {
  .talent{
    .mainText{
      .contentMainText{
        width: 80%;
      }
    }
  }
}
