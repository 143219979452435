.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 200;
  width: 100%;
  background-color: #00000040;
  display: flex;
  justify-content: center;

  .forgetContent {
    width: 100%;
    margin: 5% 27%;
    background-color: #fdfdfd;
    box-shadow: 0px 16px 24px -4px rgba(5, 3, 26, 0.16),
      0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.03);

    .close {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      button {
        padding: 24px 24px 0 0;
        width: 16px;
        height: 16px;
      }
    }

    .forgetInnerContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 5%;
      gap: 24px;

      .actionButton {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .forgetPasswordForm {
        height: 400px;
        padding: 0 120px;
        overflow-y: scroll;
      }
    }
  }

  .loadingModal {
    position: absolute;
    top: calc(228px);
    width: 228px;
    height: 228px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.06);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 23px;

    .loadingIcon {
      width: 80px;
      height: 80px;

      -webkit-animation: spin 3ms linear infinite;
      -moz-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
  }
}

@media (max-width: 1440px) {
  .modal {
    .forgetContent {
      .forgetInnerContent {
        .actionButton {
          width: 80%;
        }

        .forgetPasswordForm {
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .modal {
    .forgetContent {
      margin: 3% 21%;
    }
  }
}

@media (max-width: 640px) {
  .modal {
    .forgetContent {
      margin: 3% 10%;
    }
  }
}

@media (max-width: 535px) {
  .modal {
    .forgetContent {
      margin: 3% 3%;
    }
  }
}

@media (max-width: 425px) {
  .modal {
    .forgetContent {
      .forgetInnerContent {
        .actionButton {
          width: 90%;
        }

        .forgetPasswordForm {
          width: 90%;
        }
      }
    }
  }
}

.confirmChangePasswordModal {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-bottom: 32px;

  .icon {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 425px) {
  .confirmChangePasswordModal {
    .labelW400H20 {
      text-align: center;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
