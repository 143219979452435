.LoginFormContent {
    display: flex;
    flex-direction: column;
    width: 384px;
    height: 318px;
    top: 353px;
    left: 88px;
    gap: 32px;

    .loginFormEmailPassword {
        display: flex;
        flex-direction: column;
        width: 384px;
        height: 156px;
        gap: 16px;

        .isForgetPassword {
            display: flex;
            justify-content: space-between;

            .checkBox {
                display: flex;
                gap: 8px;
            }
        }
    }
}

.forgetPasswordForm{
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 440px;

    .timer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
}