.news {
  padding-left: 7%;
  padding-right: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .leftContent {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .leftContentText {
      width: 388px;
    }
  }

  .rightContent {
    display: flex;
    flex-direction: column;

    img {
      height: 30rem;
    }

    .newsCard {
      width: 387px;
      border-radius: 4px;
      box-shadow: 0px 2px 4px 0px rgba(5, 3, 26, 0.08), 0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.03);

      .header {
        height: 56px;
        background-color: #000;
        margin: 0px;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          display: flex;
          rotate: 3deg;
        }
      }

      .headerJornal {
        height: 56px;
        background-color: #000;
        margin: 0px;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          display: flex;
          rotate: -3deg;
        }
      }

      .content {
        width: 327px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: center;
        gap: 16px;
        padding: 32px;
      }
    }
  }
}

@media (max-width: 1170px) {
  .news {
    padding-left: 5%;
    padding-right: 5%;
  }

  .news .rightContent img {
    height: 28rem;
  }
}

@media (max-width: 1024px) {
  .news {
    flex-direction: column;
    gap: 3rem;
  }

  .news .leftContent {
    gap: 2rem;
  }

  .news .leftContent .leftContentText {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .news .rightContent img {
    height: 20rem;
  }

  .news .leftContent {
    gap: 1rem;
  }

  .labelTitle .labelTitleContent {
    font-size: 2rem;
  }

  .labelTitle .labelTitleContent .labelTitleDetail {
    font-size: 2rem;
  }
}

@media (max-width: 375px) {
  .news .rightContent img {
    height: 18rem;
  }
}

// div#card-forbes {
//   rotate: -3deg;
// }

// div#card-jornal {
//   rotate: 3deg;
// }

// div#card-bazar {
//   rotate: -3deg;
// }

// .h16 {
//   height: 16px;
// }

// @media (max-width: 768px) {
//   .news {
//     flex-direction: column;
//     padding: 0px 72px;
//     gap: 120px;

//     .leftContent {
//       .leftContentText {
//         width: 100%;
//       }
//     }
//   }
// }

// .newsFollow {
//   margin: 160px 120px 0 120px;
//   display: flex;
//   justify-content: space-between;

//   .socialNetworks {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;

//     .icons {
//       display: flex;
//       align-items: center;
//       justify-content: flex-end;
//       gap: 16px;

//       img {
//         cursor: pointer;
//       }
//     }
//   }
// }

// @media (max-width: 415px) {
//   .news {
//     gap: 72px;

//     .leftContent {
//       .leftContentText {
//         width: 100%;
//       }
//     }

//     .rightContent {
//       width: 600px;

//       .newsCard {
//         width: 200px;

//         .content {
//           width: 130px;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 415px) {
//   .newsFollow {
//     margin: 200px 55px 0 55px;
//     flex-direction: column;
//     gap: 32px;

//     .socialNetworks{
//       display: flex;
//       gap: 8px;

//       .icons{
//         justify-content: start;
//       }
//     }
//   }
// }
