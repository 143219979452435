.policy {
  position: relative;
  display: flex;
}

@media (max-width: 960px) {
  .policy .policyTerm {
    display: none;
  }
}
