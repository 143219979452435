.aboutUs {
  width: 100%;

  .mainBanner {
    height: 480px;
    padding-left: 1%;
    padding-right: 1%;

    .context {
      height: 100%;
      width: 100%;
      background-image: url('../../assets/images/bannerAboutUs.webp');
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.h160 {
  height: 160px;
}

@media (max-width: 535px) {
  .aboutUs {
    .mainBanner {
      .context{
        img {
          height: 4rem;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .aboutUs {
    .mainBanner {
      .context{
        img {
          height: 3rem;
        }
      }
    }
  }
}
