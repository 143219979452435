.mainBannerText {
    margin: 0px;
    position: absolute;
    top: calc(50% - 80px/2);
    left: calc(50% - 473px/2);
}

.logoBlue {
    width: 188.67px;
    height: 32px;
}
